import React, { Suspense } from "react"

import useModal from "../../hooks/useModal"

const AvailabilityForm = React.lazy(() => import("../modal/content/AvailabilityForm"));
const FinanceForm = React.lazy(() => import("../modal/content/FinanceForm"));
const CalculatorModal = React.lazy(() => import("../modal/financing/CalculatorModal"));

export default function VDPModalContainer(props) {
  const { lead_url, site_id, gtmId } = props.siteData
  const { disclaimerContact, disclaimerFinanceApp, disclaimerCalculator } = props.siteData
  const { defaultMonth, downpayment, rates } = props.financeData
  const document = typeof window !== 'undefined' && window.document

  const [renderAvailabilityFormModal, visibleAFM] = useModal({
    title: "",
    location: "#contact"
  })

  const [renderFinanceAppFormModal, visibleFFM] = useModal({
    title: "",
    location: "#financeapp"
  })

  const [renderFinanceCalculatorModal, visibleFCM] = useModal({
    title: "ESTIMATE PAYMENT",
    location: "#financecal",
    responsiveFluid: true,
  })

  const availabilityFormModal = typeof window !== 'undefined' &&
    (
      <Suspense fallback={<div>Loading...</div>}>
        <AvailabilityForm autofillable contextualized gtmId={gtmId} siteID={site_id} leadURL={lead_url}
          disclaimerContact={disclaimerContact}
          pageSource='Contact' pageURL={document.URL}
        />
      </Suspense>
    )

  const financeAppFormModal = typeof window !== 'undefined' &&
    (
      <Suspense fallback={<div>Loading...</div>}>
        <FinanceForm contextualized gtmId={gtmId} siteID={site_id} leadURL={lead_url}
          disclaimerFinanceApp={disclaimerFinanceApp}
          pageSource='FinanceApp' pageURL={document.URL}
        />
      </Suspense>
    )

  const financeCalculatorModal = typeof window !== 'undefined' &&
    (
      <Suspense fallback={<div>Loading...</div>}>
        <CalculatorModal contextualized gtmId={gtmId} siteID={site_id} leadURL={lead_url}
          defaultMonth={defaultMonth} downpayment={downpayment} rates={rates}
          disclaimerCalculator={disclaimerCalculator} />
      </Suspense>
    )

  return (
    <>
      {visibleAFM && renderAvailabilityFormModal(availabilityFormModal)}
      {visibleFFM && renderFinanceAppFormModal(financeAppFormModal)}
      {visibleFCM && renderFinanceCalculatorModal(financeCalculatorModal)}
    </>
  )
}