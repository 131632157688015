import React, { useState } from "react"
import Layout from '../components/layout'

import VDPModalContainer from "../components/vdp/VDPModalContainer"
import VDPHeader from "../components/vdp/VDPHeader"
import VDPFinancing from "../components/vdp/VDPFinancing"
import VDPAbout from "../components/vdp/VDPAbout"
import VDPDescription from "../components/vdp/VDPDescription"
import VDPOptions from "../components/vdp/VDPOptions"
import VDPRecommended from "../components/vdp/VDPRecommended"
import VDPImageSlider from "../components/vdp/VDPImageSlider"
import useIncentives from '../components/vdp/hooks/VDPIncentives'

import style from "../components/vdp/vdp.module.scss"
import { configurableString } from "../Modules/configurableVehicleInfo"
import { FinanceCalculatorProvider } from "../components/modal/financing/storage/CalculatorContext"
import InnerHTML from 'dangerously-set-html-content'

export default function Vehicle(props) {
  const {
    vehicle, similarRVs, theVDPSlug, theVDPseo, financeButtons, fallbackText, rates,
    unavailableImg, disclaimer, disclaimerCalculator, disclaimerContact, disclaimerFinanceApp, vehiclecardbutton, site_id, lead_url,gtmId,PosDifText,NegDifText,retailPriceCustomText,retailPriceDiscountCustomText,MSRPCustomText,netPriceCustomText,HideNewUsed,decimalSpaceHide, strikeMSRP,
  } = props.pageContext
  const { topHtmlVDP, botHtmlVDP, AutoCheckEnabled } = props.pageContext
  
  const { ShowLocationFilter } = props.pageContext

  const document = typeof window !== 'undefined' && window.document

  const months = parseFloat(props.pageContext.months)
  const downpayment = parseFloat(props.pageContext.downpayment)

  const [extraData, setExtra] = useState({})

  function formatPrice(price, defaultPrice) {
    var decSpot = decimalSpaceHide ? 0 : 2
    let thePrice = (defaultPrice != undefined && price == 0
      ? defaultPrice
      : '$' + price.toFixed(decSpot).replace(/\d{1,3}(?=(\d{3})+\.?)/g, '$&,'))
    return thePrice
  }
  
  const showPhoto = () => {
    let mainPhoto = {}
    if (vehicle.MainPhotoUrl === "") {
      mainPhoto = unavailableImg
    } else {
      mainPhoto = vehicle.MainPhotoUrl;
    }
    return mainPhoto
  }
  const [,incentivesSum]  = useIncentives(vehicle.ListOfAppliedOffers,vehicle.Pricing);
  const finalPrice = Number(vehicle.Pricing.ExtraPrice1 || vehicle.Pricing.List);
  const configurablePriceInfo = finalPrice - incentivesSum;

  const vdpSEO = { seo: theVDPseo }

  vdpSEO.seo.MetaDesc = configurableString(vdpSEO.seo.MetaDesc, vehicle.VehicleInfo, showPhoto(), configurablePriceInfo,vehicle.Pricing.List,vehicle.Pricing.ExtraPrice1)
  vdpSEO.seo.MetaDesc = (vdpSEO.seo.MetaDesc == "" ? "Vehicle Description" : vdpSEO.seo.MetaDesc)

  vdpSEO.seo.MetaTitle = configurableString(vdpSEO.seo.MetaTitle, vehicle.VehicleInfo, showPhoto(), configurablePriceInfo,vehicle.Pricing.List,vehicle.Pricing.ExtraPrice1)
  vdpSEO.seo.MetaTitle = (vdpSEO.seo.MetaTitle == "" ? "Vehicle Description" : vdpSEO.seo.MetaTitle)

  function loadFirstPhoto (photoList) {
    let mainPhoto = ""
    if (!Array.isArray(photoList) || photoList.length === 0) {
      mainPhoto = unavailableImg
    } else {
      mainPhoto = photoList[0].PhotoUrl
    }
    return mainPhoto
  }

  const renderBody = () => (
    <>
      <link rel="preload" as="image" href={loadFirstPhoto(vehicle.ListOfPhotos)} />
      <FinanceCalculatorProvider>
        <VDPModalContainer
          siteData={{
            lead_url, site_id, gtmId,
            disclaimerContact, disclaimerFinanceApp, disclaimerCalculator
          }}
          financeData={{ rates, downpayment, defaultMonth: months }}
        />

        <main className={style["main"]}>
          <VDPImageSlider vehicle={vehicle} unavailableImg={unavailableImg} />
          <VDPFinancing vehicle={vehicle} buttons={financeButtons} AutoCheckEnabled={AutoCheckEnabled}
            disclaimer={disclaimer} financing={{ months, downpayment, rates }}
            actionData={extraData} leadURL={lead_url} siteID={site_id}
            disclaimerCalculator={disclaimerCalculator} fallbackText={fallbackText} PosDifText={ PosDifText } NegDifText={ NegDifText } retailPriceCustomText={ retailPriceCustomText } retailPriceDiscountCustomText={ retailPriceDiscountCustomText } MSRPCustomText={ MSRPCustomText } netPriceCustomText={ netPriceCustomText } strikeMSRP={strikeMSRP} />
          <VDPAbout vehicle={vehicle} />
          <VDPDescription vehicle={vehicle} />
          <VDPOptions vehicle={vehicle} />
        </main>

        <VDPRecommended recommendation={similarRVs} slug={theVDPSlug} AutoCheckEnabled={AutoCheckEnabled}
          buttons={vehiclecardbutton} unavailableImg={unavailableImg} retailPriceCustomText={ retailPriceCustomText }retailPriceDiscountCustomText={ retailPriceDiscountCustomText }MSRPCustomText={ MSRPCustomText }
          catchActionData={setExtra} fallbackText={fallbackText} ShowLocationFilter={ShowLocationFilter} decimalSpaceHide={decimalSpaceHide} strikeMSRP={strikeMSRP}/>
      </FinanceCalculatorProvider>
    </>
  )


  return (
    <Layout seo={vdpSEO}>
      <div className={style["body"]} style={{ paddingTop: "19px" }}>
        <div className={style["page-wrap"]}>
          <InnerHTML html={configurableString(( typeof topHtmlVDP === 'undefined' ? "" : topHtmlVDP), vehicle.VehicleInfo,  showPhoto(), configurablePriceInfo, vehicle.Pricing.List,vehicle.Pricing.ExtraPrice1)} />
          <VDPHeader vehicle={vehicle} ShowLocationFilter={ShowLocationFilter} HideNewUsed={HideNewUsed} />
          {renderBody()}
          <InnerHTML html={configurableString((typeof botHtmlVDP === 'undefined' ? "" : botHtmlVDP), vehicle.VehicleInfo, showPhoto(), configurablePriceInfo, vehicle.Pricing.List,vehicle.Pricing.ExtraPrice1)} />
        </div>
        <div className="vdp-sr-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }} />
        <data id="page" value="Vehicle Display Page"></data>
        <data id="vin" value={vehicle.VehicleInfo.VIN}></data>
        <data id="stock" value={vehicle.VehicleInfo.StockNumber}></data>
        <data id="condition" value={vehicle.VehicleInfo.IsNew ? 'New' : 'Pre-Owned'}></data>
        <data id="price" value={vehicle.Pricing.ExtraPrice1}></data>
        <data id="year" value={vehicle.VehicleInfo.Year}></data>
        <data id="make" value={vehicle.VehicleInfo.Make}></data>
        <data id="model" value={vehicle.VehicleInfo.Model}></data>
        <data id="trim" value={vehicle.VehicleInfo.Trim}></data>
        <data id="color" value={vehicle.VehicleInfo.ExteriorColor}></data>
        <data id="category" value={vehicle.VehicleInfo.BodyType}></data>
        <data id="fuel" value={vehicle.VehicleInfo.EngineFuelType}></data>
        <data id="type" value="vehicle"></data>
        <data id="category" value={vehicle.VehicleInfo.BodyType}></data>
        <data id="date" value={vehicle.VehicleInfo.InStockDate}></data>
      </div>
    </Layout>
  )
}

Vehicle.defaultProps = {
  pageContext: {
    months: 0,
    downpayment: 0,
  }
}
