import React from "react"
import style from "../vdp.module.scss"

export default function useOptionalIncentives(incentivesList = [], Pricing) {

  const optionalIncentiveSum = (Pricing.List > 0) && Array.isArray(incentivesList) ? 
    incentivesList.map(incentive => {
      return parseFloat(incentive.Price.replace("$",""))
    }).reduce((prev, curr) => {
      return prev + curr
    }, 0) : 0

  const renderOptionalIncentives = () => (
    (optionalIncentiveSum > 0) &&
    <div style={{ marginBottom: "1em" }}>
      {/* <hr style={{ width: "100%" }} /> */}
      <p className={style["offer-name"]}>Additional Savings - Conditional Offers</p>
      {incentivesList.filter(incentive => {return incentive.Price != "" && incentive.Price != "$0" && (incentive.Description && (incentive.Description.length > 0))}).map(incentive => (
        <div className={style["vdp-incentives"]}>
          <div style={{ position: "relative", paddingRight: "24px", paddingTop: "10px" }}>
            <p>{incentive.Name}</p>
            {
              incentive.Description.length > 0 &&
              <>
                <span className={style["incentive-info"]}>
                </span>
                <div className={style["incentive-detail"]}>
                  <p>{incentive.Description ? (incentive.Description !="No description" ? incentive.Description : ''): ''}</p>
                  {incentive.EndDate === "" ? <span>See dealer for more details and offer end date.</span> :  <span>Offer valid through: {incentive.EndDate}. See dealer for more details.</span> }
                </div>
              </>
            }
          </div>
          <span>{`${incentive.Price}`}</span>
        </div>
      ))
      }
      <hr style={{ width: "100%" }} />
    </div>
  )

  return [renderOptionalIncentives, Number(optionalIncentiveSum)]
}